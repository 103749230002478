<script>

export default {
  data() {
    return {
      update: 0,
      model: {},
    };
  },
};
</script>
<template lang="html">
  <div>
    <b-row>
      <b-col md="12" class="p-0">
        <transition name="fade-in-up" mode="out-in">
          <router-view/>
        </transition>
      </b-col>
    </b-row>
  </div>

</template>
